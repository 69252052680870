// stylelint-disable selector-class-pattern

.transactionWrapper,
.transactionBar {
  display: inline-flex;
  height: 1rem;
  margin: 0;
}

.transactionWrapper {
  width: 5rem;
  white-space: nowrap;
  vertical-align: middle;
}

.transactionBar {
  vertical-align: middle;
}

.transactionBar.buyer {
  background-color: $color-buyer;
}

.transactionBar.seller {
  background-color: $color-seller;
}

.transactionBar.holder {
  background-color: $color-holder;
}
