//stylelint-disable font-family-no-missing-generic-family-keyword
.bookmark-list {
  padding: 0;
}

.bookmark-item {
  $width-label: 30px;
  position: relative;
  display: grid;
  grid-template:
    "tit" auto
    "description" auto
    "actions" 1fr / 1fr;
  grid-gap: s(3);
  padding: s(3) s(3) s(3) 2.5rem;
  list-style-type: none;
  background-color: $white;
  //border-left: 30px solid $primary-light;
  box-shadow: $box-shadow-sm;

  @include media-breakpoint-up("lg") {
    grid-template:
      "tit tit" auto
      "actions description" 1fr / 1fr auto;
  }

  &::after {
    position: absolute;
    top: 1em;
    left: s(3);
    display: inline-block;
    margin-top: -1px;
    font-family: FontAwesome;
    font-size: inherit;
    color: $primary;
    content: "\f097"; // fa-bookmark-o
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  & + & {
    margin-top: s(3);
  }
}

.bookmark-title {
  margin-bottom: 0;
  font-weight: $font-weight-bold;
}

.bookmark-description {
  grid-area: description;
  align-self: center;
}

.bookmark-actions {
  grid-area: actions;

  > * {
    &:not(:first-child) {
      margin-left: s(2);
    }
  }
}

