// stylelint-disable declaration-no-important
.choose-pdf-report-elements-dialog {
  .msg-in-progress {
    display: none;
  }
  .spinner-wrapper {
    height: 220px;
  }

  &.downloading {
    min-height: 150px !important;
    .msg-in-progress {
      display: block;
    }
    .sections,
    .form-actions-buttons {
      display: none;
    }
  }
}

