.tabsgroup {
  display: flex;
  flex-wrap: wrap;
}

.tabsgroup .nav-link {
  @extend .btn;
  @extend .btn-light;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $text-muted;
  background-color: $white;

  &.active {
    color: $secondary;
    background-color: $gray-100;
  }

  &:focus,
  &:focus-within {
    background-color: $gray-100;
    outline: 0;
    //stylelint-disable-next-line declaration-no-important
    box-shadow: none !important;
  }
}

.tabsgroup .nav-link.dropdown-toggle {
  display: inline-flex;
  gap: s(1);
  align-items: center;
}

.tabsgroup > .nav-item:not(:last-child) > .nav-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tabsgroup > .nav-item:not(:first-child) > .nav-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
