.holding-info {
  display: inline-flex;
  gap: s(2);
  align-items: center;
  font-size: $font-size-sm;
  color: $text-muted;
}

.holding-info .badge {
  font-size: 90%;

  &.newbuyer {
    color: $white;
    background-color: $color-newbuyer;
  }

  &.buyer {
    color: $body-color;
    background-color: $color-buyer;
  }

  &.totalseller {
    color: $white;
    background-color: $color-totalseller;
  }

  &.seller {
    color: $body-color;
    background-color: $color-seller;
  }

  &.holder {
    color: $body-color;
    background-color: $color-holder;
  }
}
