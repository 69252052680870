$panel-padding-x: s(2);
$panel-padding-y: s(2);
$panel-font-size: $font-size-sm;

.panel {
  margin-bottom: s(3);
  font-size: $panel-font-size;
  background-color: $white;
  box-shadow: $box-shadow-sm;

  a {
    text-decoration: none;
  }

  select {
    @extend .form-select;
    @extend .form-select-sm;
  }
}

.panel-heading {
  display: flex;

  padding: $panel-padding-y $panel-padding-x;
  border-bottom: 1px solid $primary-light;

  .right {
    order: 1;
    margin-left: auto;
  }

  a {
    text-decoration: none;
  }

  .nav-tabs {
    border-bottom: none;

    > *:not(:first-child) {
      margin-left: s(2);
    }
  }
}

.nav-tabs .nav-item {
  > * {
    opacity: .3;

    &.active {
      opacity: 1;
    }
  }
}

.panel-title {
  margin-bottom: 0;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.panel-report {
  h2 {
    @extend .panel-heading;
    @extend .panel-title;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .dataTables_wrapper {
    box-shadow: unset;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .dataTables_length {
    order: 1;
    margin-left: auto;
  }

  .dt-buttons {
    margin-left: unset;
  }
}

.panel-collapsible-trigger {
  display: flex;
  align-items: center;
  padding: $panel-padding-y s(3);
  font-size: $panel-font-size;
  cursor: pointer;
  background-color: $white;
  border-radius: $border-radius;
}

.panel-collapsible-title {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $primary;
}

.panel-collapsible-title-extra {
  padding-left: s(1);
  color: $gray-600;
}

.panel-collapsible-icon {
  margin-left: auto;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $primary;
}

.panel-collapsible-body {
  padding: 0 s(3) s(3);
  font-size: $font-size-sm;
  background-color: $white;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

