$dir-fonts: "/fonts/";

@font-face {
  font-family: HelveticaNeueLTStd;
  font-style: normal;
  font-weight: $font-weight-thin;
  font-stretch: normal;
  src: url("#{$dir-fonts}HelveticaNeueLTStd-Th.woff2") format("woff2"), url("#{$dir-fonts}HelveticaNeueLTStd-Th.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLTStd;
  font-style: normal;
  font-weight: $font-weight-light;
  font-stretch: normal;
  src: url("#{$dir-fonts}HelveticaNeueLTStd-Lt.woff2") format("woff2"), url("#{$dir-fonts}HelveticaNeueLTStd-Lt.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLTStd;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-stretch: normal;
  src: url("#{$dir-fonts}HelveticaNeueLTStd-Roman.woff2") format("woff2"), url("#{$dir-fonts}HelveticaNeueLTStd-Roman.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLTStd;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-stretch: normal;
  src: url("#{$dir-fonts}HelveticaNeueLTStd-Bd.woff2") format("woff2"), url("#{$dir-fonts}HelveticaNeueLTStd-Bd.woff") format("woff");
  font-display: swap;
}
