.reconciliation {
  vertical-align: middle;

  .dropdown {
    width: 100% !important;
  }

  .dropdown-toggle {
    @extend .form-select-sm;

    &:hover {
      @extend .form-select-sm;
    }
  }

  .disabled td{
    @extend .text-muted;
  }

  .disabled select,
  .disabled .dropdown {
    display: none;
  }
}

.reconcile-form-wrapper .dataTables_wrapper {
  overflow: visible;
}