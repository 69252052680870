.widget-header {
  display: flex;
  align-items: center;
  margin-bottom: s(4);
}

.widget-toggler{
  text-decoration: none;
}

.widget-content {
  min-height: s(4);
  margin-top: - s(4);
}

.widget-table {
  margin-bottom: 0;
}

/*
there is a collision with another element that uses the class with JS to work,
for now we change the name of the class
*/
.widget-expand-screen {
  position: absolute;
  right: 0;
  bottom: - s(2);
  left: 0;
  z-index: $zindex-sticky;
  display: inline-block;
  height: 1em;
  color: $primary;
  text-align: center;
  cursor: pointer;
  background-color: $body-bg;
  border-top: 1px solid $gray-300;
  transition: border .3s ease-out, color .5s ease-out;

  &:hover {
    color: $secondary;
    border-top-color: inherit;
  }

  i {
    position: relative;
    top: -.75em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    background-color: $body-bg;
  }
}

.widget-top-sidebar {
  padding-top: s(3);
  margin-bottom: s(2);
}

