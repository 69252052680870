// stylelint-disable selector-class-pattern
.match_info {
  @extend .badge;
  border-radius: $border-radius-pill;
}

.match_info.noposition {
  color: $body-color;
  background-color: $gray-300;
}

.match_info.newbuyer {
  background-color: $ticker;
}

.match_info.buyer {
  color: $body-color;
  background-color: lighten($ticker, 30);
}

.match_info.totalseller {
  background-color: $red;
}

.match_info.seller {
  color: $body-color;
  background-color: lighten($red, 30);
}
