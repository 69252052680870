.layout-main {
  position: relative;
  display: flex;
  flex-grow: 1;

  footer {
    padding: 10px;
    text-align: center;
    font-size: 12px;

    hr {
      border: none;
      border-top: 1px solid;
      margin: 1em 3%;
    }
  }
}
