//stylelint-disable selector-class-pattern
//stylelint-disable selector-id-pattern
//stylelint-disable selector-max-id
//stylelint-disable selector-no-qualifying-type
//stylelint-disable declaration-no-important
@import "dataTables-bootstrap5";

// header
.dt-header {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  align-items: center;
  padding: $dt-padding;
  background-color: $dt-header-bg;
  border-bottom: $dt-border;

  &:empty {
    display: none;
  }

  > :empty {
    display: none;
  }

  .hide_show_rows:empty {
    display: none;
  }

  .title {
    font-weight: $font-weight-bold;
  }


  #leftControls {
    display: flex;
    gap: .5rem;
  }

  select {
    @extend .form-select;
    @extend .form-select-sm;
  }

  .dataTables_length {
    > label {
      display: flex;
      gap: .25rem;
      align-items: center;
    }
  }

  #user-list_length {
    order: 1;
    margin-left: auto;
  }

  #user-jobs-table_length {
    order: 1;
    margin-left: auto;
  }

  #all-jobs-table_length {
    order: 1;
    margin-left: auto;
  }

  #online-list_length {
    order: 1;
    margin-left: auto;
  }

  #user-activity_length {
    order: 1;
    margin-left: auto;
  }

  #user-reports_length {
    order: 1;
    margin-left: auto;
  }

  #screenResultsTable_filter {
    order: -1;
    margin-right: auto;
  }

  #conversations-table_filter {
    order: -1;
    margin-right: auto;
  }

  #user-lists-table_filter {
    order: -1;
    margin-right: auto;
  }

  #list-table_filter {
    order: -1;
    margin-right: auto;
  }

  #list-table_length {
    order: 1;
  }

  #portfolio_market_table_length {
    order: 2;
  }

  #holding_table_length {
    order: 2;
  }

    .industry_filter_control_div {
    display: flex;
    order: 1;

    label {
      display: inline-flex;
      gap: .25rem;
    }

    input[type="radio"] {
      @extend .form-check-input;
      flex-shrink: 0;
    }
  }

  #users-filter {
    margin-right: 1rem;
    > form {
      display: flex;
      gap: .75rem;
    }

    label {
      display: inline-flex;
      gap: .25rem;
    }

    input[type="radio"] {
      @extend .form-check-input;
      flex-shrink: 0;
    }
  }

  #jobs-filter {
    margin-right: 1rem;
    > form {
      display: flex;
      gap: .75rem;
    }

    label {
      display: inline-flex;
      gap: .25rem;
    }

    input[type="radio"] {
      @extend .form-check-input;
      flex-shrink: 0;
    }
  }
}

.dataTables_filter{
  display: flex;
  label {
    display: block;

    input[type="search"] {
      margin-left: .5em !important;
    }
  }

  input[type="search"]{
    margin-left: 0 !important;
    @extend .form-control;
    @extend .form-control-sm;
  }

  &#table-views-table_filter {
    float: right;
    padding: s(1);
    overflow: hidden;
  }

  &#table-shared-views-table_filter {
    float: right;
    padding: s(1);
    overflow: hidden;
  }
}

.dt-buttons {
  margin-left: auto;
}

.dataTables_empty {
	text-align: center;
	padding: s(4) !important;
}

// table
table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  thead {
    background-color: $primary;
    width: 100%;
    th {
      color: $white;
      border-bottom: 1px solid $primary-light;
    }
  }
}

/* stylelint-disable-next-line selector-max-type */
table.DTFC_Cloned thead tr {
  background-color: transparent;
}

.dataTables_scroll {
  background-color: $white;
  //border-bottom: 1px solid $primary;
}

.dataTables_wrapper {
  position: relative;
  overflow-x: auto;
  font-size: $font-size-sm;
  box-shadow: $box-shadow;

  table {
    background-color: $white;

    &:not(.table-sm) {
      th, td {
        padding: $table-cell-padding-y $table-cell-padding-x;
      }
    }
    th, td {
      .dropdown-toggle:empty::after{
        display: none;
      }
    }
  }

  table.peer-list-companies-table {
    tr:last-child td {
      padding-bottom: 1rem;
    }
  }

  .nowrap td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td.nowrap {
    white-space: nowrap;
  }

  .positive {
    color: $success;
  }

  .negative {
    color: $danger;
  }

  .show_funds_modal {
    margin-right: .5em;
  }

  .controls-icons {
    display: inline-flex;
    align-items: center;
    font-size: 1.1rem;
  }

  .controls-icons .icon {
    margin-right: s(1);
    width: 1rem;
  }

  .portfolio-name-wrapper {
    position: relative;
    padding-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .portfolio-name-wrapper .target-indicator, .portfolio-name-wrapper .nomatch-indicator {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    transform: translateY(-50%);
  }


  .dataTables_processing {
    z-index: 1000;
    width: 13rem;
    padding: s(2) !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $white;
    background-color: rgba($primary, .75);
    border-radius: $border-radius-sm;
    transform: translateX(-50%);
  }

  td.actions > *:not(:first-child){
    margin-left: s(2);
  }

  #table-shared-views-table_paginate {
    padding: s(1);
  }

  #table-views-table_paginate {
    padding: s(1);
  }

  #industry_data {
    width: 100% !important;
  }
}

.dt-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $dt-padding;
  background-color: $dt-footer-bg;
  border-top: $dt-border;
}

.dataTables_info {
  padding-top: 0 !important;
  margin-left: 1rem;
  font-size: $font-size-xs;
  color: $body-color;
}

.dataTables_paginate {
  @extend .pagination;

  span {
    display: flex;
    margin-left: -1px;
  }
}

// pagination
.paginate_button {
  @extend .page-link;

  &:not(:first-of-type) {
    margin-left: -1px;
  }

  &.first {
    border-top-left-radius: $pagination-border-radius;
    border-bottom-left-radius: $pagination-border-radius;
  }

  &.disabled {
    pointer-events: none;
    opacity: .65;
  }

  &.current {
    color: $pagination-active-color;
    @include gradient-bg($pagination-active-bg);
    border-color: $pagination-active-border-color;
  }

  &.last {
    border-top-right-radius: $pagination-border-radius;
    border-bottom-right-radius: $pagination-border-radius;
  }
}

.ellipsis {
  @extend .page-link;
  margin-left: -1px;
}

#companies-table {
  display: none;

  .dataTables_wrapper & {
    display: table;
  }
}

#companies-table_wrapper .dt-header {
  display: none;
}

#top-targets-table {
  display: none;

  .dataTables_wrapper & {
    display: table;
  }
}

#model-scores-table {
  display: none;

  .dataTables_wrapper & {
    display: table;
  }
}

#list-table_wrapper .DTFC_ScrollWrapper {
  height: auto !important;
}

#top-matching-fund-table {
  th {
    padding: 0;
    border-bottom: unset;
  }
}

#conversations-table_wrapper .detailed tbody tr, #conversations-table_wrapper .detailed tbody td {
  height: 40px;
}

#vm-cdr-preview-table_wrapper {
  .dataTables_scroll {
    overflow: auto;
  }
  .dataTables_scrollHead {
    overflow: initial !important;
  }
  .dataTables_scrollBody {
    overflow: initial !important;
  }
  tbody {
    cursor: pointer;
  }
}

.notes-table-wrapper .dataTables_filter {
  order: -1;
}

.overrides-table-wrapper {
  .dataTables_filter {
    order: -1;
  }
  .dataTables_length {
    margin-left: auto;
  }
}

table.dataTable.table-striped > tbody > tr.odd {
  --bs-table-accent-bg: initial;
}

#table-views-table_wrapper table {
  width: 100% !important;
}

#table-shared-views-table_wrapper table {
  width: 100% !important;
}

.table-nowrap {
  > tbody {
    > tr {
      > td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
