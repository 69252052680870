.user-info {
  display: grid;
  grid-template:
    "name icon" auto
    "company icon" auto / 1fr auto;
  column-gap: s(2);
  line-height: 1.2;
  color: $white;

  @include media-breakpoint-down("lg"){
    padding-bottom: 1rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba($white, .5);

    &:hover {
      color: $white;
    }
  }

  @include media-breakpoint-up("lg"){
    --user-info-name--color: #{$primary};
    --user-info-company--color: #{$text-muted};
    --user-info-icon--color: #{$primary};
    grid-template:
      "icon name" auto
      "icon company" auto / auto 1fr;
    padding-right: s(3);
    font-size: $font-size-xs;
  }
}

.user-info-name {
  grid-area: name;
  color: var(--user-info-name--color, inherit);
}

.user-info-company {
  grid-area: company;
  color: var(--user-info-company--color, inherit);
}

.user-info-icon {
  grid-area: icon;
  align-self: center;
  color: var(--user-info-icon--color, inherit);
}

.user-info-reports {
  font-size: .75rem;
  color: #{$text-muted};
  cursor: pointer;
}

.user-info-reports-spinner {
  margin-right: 0.3rem;
  color: #{$text-muted};
}
