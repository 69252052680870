.jump-button {
  @extend .btn;
  @extend .btn-light;
  display: inline-flex;
  align-items: center;
  padding:
    var(--jump-button--padding-y, $btn-padding-y)
    var(--jump-button--padding-x, $btn-padding-x);
  white-space: nowrap;
  border-radius: $border-radius-pill;

  &:hover {
    background-color: $gray-200;
  }
}

.jump-button-text {
  font-size: $font-size-sm;
}

.jump-button-title {
  text-transform: uppercase;
}

.jump-button-icon {
  flex-shrink: 0;
  width: s(4);
  height: s(4);
  margin-left: s(3);

  &.selected {
    color: $success;
  }

  &.unselected {
    color: $danger;
  }
}

.jump-buttons {
  --jump-button--padding-y: .15rem;
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}
