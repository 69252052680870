.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: initial;
  background-color: $primary;
  mask-image: var(--animated-spinner);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: auto;
}
