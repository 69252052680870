.show-hide-columns-list-container {
  flex-grow: 1;
  height: 60vh;
  padding-top: s(2);
  padding-bottom: s(2);
  margin-bottom: 0;
  overflow: auto;
  background-color: $light;

  lh {
    display: list-item;
    font-weight: $font-weight-bold;
  }

  li {
    padding-left: s(3);
  }

  li.sortable {
    cursor: pointer;
    &.selected {
      background-color: $navbar-light-toggler-border-color;
    }
  }

  ul:last-child {
    min-height: 60vh;
  }
}
