/* stylelint-disable selector-class-pattern */
.meeting-datepicker .time-picker {
  position: relative;
  flex-grow: 1;
}

.meeting-datepicker .time-input-control__button {
  display: none;
}

.meeting-datepicker .time-input-control__select {
  position: absolute;
  border-radius: 0;
}

.meeting-datepicker .time-input-control__input{
  position: absolute;
  width: calc(100% - 40px);
  border-radius: 0;
}

.meeting-datepicker .timezone .btn{
  @extend .btn-light;
  @extend .btn-sm;
  border: 1px solid $input-border-color;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.meeting-datepicker .timezone .caret {
  margin-right: 1.5rem;
}

.modal .meeting-datepicker {
  position: relative;
  height: 2rem;
}
