%icon {
  display: inline;
  background-color: currentcolor;
  mask: var(--icon) center/contain no-repeat;

  &::before {
    color: transparent;
    content: "M";
  }
}
