/* stylelint-disable declaration-no-important */
//stylelint-disable selector-no-qualifying-type

.filter-list {
  @include list-unstyled();
  display: none;
  flex-wrap: wrap;
  gap: s(2);
  padding-bottom: s(3);
  margin-bottom: 0;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  a{
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-size: $badge-font-size;
    color: $white;
    text-decoration: none;
    background-color: $primary-light;
    border-radius: $border-radius-pill;

    &:hover,
    &:focus {
      background-color: $primary;
    }

    &.selected {
      background-color: $primary;
    }
  }
}

.filter-container {
  padding-top: s(2);
  padding-left: s(3);
  font-size: $font-size-xs;
}

.filter{
  display: block !important;
  padding: s(3);
  border-bottom: 1px solid $border-color;

  h3 {
    margin: 0;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &.has-filter {
    .filter-badge {
      display: block;
    }
  }
}

.filter-badge {
  display: none;
}

.filters-widget {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.filters-widget-content {
  margin-top: 0 !important;
  overflow: auto;

  .slide {
    padding-left: 0;
    margin-bottom: 0;
  }

  .slide .controls {
    flex-wrap: wrap;
  }

  .slider {
    width: 100%;
    padding-right: s(3);
    padding-left: s(3);
    margin-bottom: s(2);
  }

  .slider .ui-slider {
    width: 100% !important;
  }
}

.filter-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  color: $primary;
  background: transparent;
  border: 0;
}

.widget-toggler-badge {
  display: none;

  &.has-filter {
    display: block;
  }
  .has-filter-checkboxlist & {
    display: block;
  }
  .has-filter-slider & {
    display: block;
  }
}

.top-metro-cities-filter-badge {
  @extend .badge;
  @extend .rounded-pill;
  @extend .bg-light;
  @extend .text-dark;
  @extend .mx-1;
  @extend .float-end;
  cursor: pointer;
}
