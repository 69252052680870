/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-max-type */

@import "jquery-tagsinput";

div.tagsinput {
  display: flex;
  gap: s(1);
  align-items: center;
  padding: $input-padding-y-sm $input-padding-x-sm;
  background: $input-bg;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius-sm;

  span.tag {
    @extend .badge;
    margin-right: 0;
    margin-bottom: 0;
    color: $white;
    background-color: $secondary;
    border: 0;

    a {
      color: inherit;
    }
  }

  input {
    margin: 0;
  }

  div:first-child {
    width: 100%;
  }
}
