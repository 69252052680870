// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-class-pattern

.holding_class_filter {
  display: flex;
  gap: s(2);
}

.holding_class_filter label {
  @extend .badge;
  display: inline-flex;
  gap: s(1);
  align-items: center;
  padding: s(1) s(3);
  font-weight: $font-weight-bold;
  color: $body-color;
  cursor: pointer;
  background-color: $light;
  border: 2px solid $light;
  border-radius: $border-radius-pill;
}


.holding_class_filter label.newbuyer_label.checked {
  border-color: $color-newbuyer;
}

.holding_class_filter label.buyer_label.checked {
  border-color: $color-buyer;
}

.holding_class_filter label.holder_label.checked {
  border-color: $color-holder;
}

.holding_class_filter label.seller_label.checked {
  border-color: $color-seller;
}


.holding_class_filter label.totalseller_label.checked {
  border-color: $color-totalseller;
}

.holding_class_filter input[type="checkbox"] {
  margin-top: 0;
}

.newbuyer-icon {
  color: $color-newbuyer;
}

.buyer-icon {
  color: $color-buyer;
}

.holder-icon {
  color: $color-holder;
}

.seller-icon {
  color: $color-seller;
}

.totalseller-icon {
  color: $color-totalseller;
  &.active {
    color: inherit;
  }
}

.active .totalseller-icon {
  color: $color-totalseller;
}

.noposition-icon {
  color: inherit;
}
