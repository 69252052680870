/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
.fit {
  font-weight: $font-weight-bold;
  vertical-align: middle;

  &.very_high,
  &.high {
    color: $success !important;
  }

  &.very_low,
  &.low,
  &.outlier {
    color: $red !important;
  }
}
