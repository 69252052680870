@import "jquery-taggedselect";

ul.taggedselect {
  background-color: $input-bg;
  color: $input-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius-sm;
  padding: 0 $input-padding-x-sm;
  margin: 0;
  font-size: $input-font-size-sm;
  width: 100%;

  li {
    float: none;
    border-radius: 0;
    margin: 0;
  }

  li.taggedselect-choice {
    display: inline-flex;
    margin: 2px 5px 2px 0;
  }

  li.taggedselect-new {
    padding: $input-padding-y-sm 0;
  }

  .ui-menu {
    max-width: 500px;
  }
}

.taggedselect-hiddenSelect {
  display: none !important;
}

 