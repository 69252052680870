@import "bootstrap/scss/alert";

.alert-info {
  --bs-alert-color:  #087990;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-secondary {
  --bs-alert-color: #006b84;
  --bs-alert-bg: #ccf0f8;
  --bs-alert-border-color: #b3e8f5;
}
