ul.taggedselect {
  cursor: text;
  overflow: auto;
  font-size: 14px;
  width: 500px;
  padding: 3px;
  margin-top: 2px;
  margin-right: 6px;
  margin-bottom: 16px;
  border: 1px solid #AAA;
  background: white;
  color: #555;
  min-height: 30px;
  border-radius: 3px;
}

ul.taggedselect.validateErrorLabel {
  background: #FFFBE5 url(/images/error-msg.png) no-repeat 98% 9px !important;
  border-color: red;
}

ul.taggedselect li {
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: block;
  float: left;
  margin: 2px 5px 2px 0;
  position: relative;
}

ul.taggedselect li.taggedselect-choice {
  background-color: #EDEDED;
  border: 1px solid #D9D9D9;
  padding: 2px 20px 3px 8px;

/*    background-color: #f7f7f7;
  border: 1px solid #f2f2f2;
  padding: 2px 13px 3px 4px;*/
}

ul.taggedselect li.taggedselect-choice.selected {
  background-color: #f5b0b0;
  border-color: #f76464;
}

ul.taggedselect li.taggedselect-choice:hover {
  background-color: #f2f2f2;
  border-color: #e0e0e0;
}

ul.taggedselect li.taggedselect-new {
  padding: 2px 4px 3px;
  padding: 2px 4px 1px;
  padding: 2px 4px 1px 0;
}

ul.taggedselect li.taggedselect-choice input {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  padding-top: 2px;
  padding-bottom: 2px;
}

ul.taggedselect li.taggedselect-choice a.taggedselect-close {
  color: #777777;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  outline: medium none;
  padding: 2px 0 2px 3px;
  text-decoration: none;
  position: absolute;
  display: block;
  top: 0;
  right: 6px;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.7);
}

ul.taggedselect li.taggedselect-choice a.taggedselect-close:hover {
  color: #535353;
}

ul.taggedselect input[type="text"] {
  -moz-box-sizing: border-box;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  width: inherit !important;
  outline: none;
  box-shadow: none !important;
  background: transparent !important;
}

.taggedselect-hiddenSelect {
  display:none;
}
