.auth-box {
  max-width: 100%;
  padding: s(3);
  margin: auto;
  background: rgba($white, 1);
  border-radius: $border-radius-lg;
  box-shadow: 0 1rem 3rem rgba($black, .3);

  .auth-form {
    width: 30rem;
  }
}
