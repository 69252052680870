@import "bootstrap-select";

.bootstrap-select {
  .dropdown-toggle {
    @extend .form-select;
  
    &:hover {
      @extend .form-select;
    }
  
    &:focus {
      outline: 0 !important;
      border-color: $form-select-focus-border-color;
    }
  
    &::after {
      border: 0;
      background-image: $form-select-indicator;
      background-size: $form-select-bg-size;
    }
  }

  &.form-select-sm .dropdown-toggle, {
    @extend .form-select-sm;
    
    &:hover {
      @extend .form-select-sm;
    }
  }

  .bs-ok-default:after {
    width: 0.4em;
    height: .8em;
    border-width: 0 2px 2px 0;
    border-color: $success;
  }

  &.show-tick .dropdown-menu .selected span.check-mark {
    top: 10px;
  }
} 

