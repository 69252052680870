.main-content {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  transition: padding-left .5s ease-out;
  animation: fade-in .5s 1s ease-out both;

  @include media-breakpoint-up ("lg") {
    padding-left: $main-sidebar-width-collapsed;
  }
}
