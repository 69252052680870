//stylelint-disable selector-no-qualifying-type

.filter-toggles {
  @extend .list-unstyled;
  display: flex;
  gap: map-get($spacers, 1);

  a {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-light;

    &.selected {
      @extend .btn-secondary;
    }
  }
}
