table {
  --bs-table-bg: transparent !important;

  .activist {
    color: $activist-color !important;
    i.fa {
      color: $activist-color !important;
    }
  }
  .invasive {
    color: $invasive-color !important;
    i.fa {
      color: $invasive-color !important;
    }
  }
  .passive {
    color: $passive-color !important;
    i.fa {
      color: $passive-color !important;
    }
  }

  .numeric{
    text-align: right;
  }

  .ca {
    text-align: center;
  }

  .nowrap-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .sorting_1 {
    background-color: $secondary-lighter;
  }

  div.scaled-fit {
    overflow: hidden;
    width: 200px;
    margin-top: -s(2);
    margin-bottom: -s(2);

    img {
      width: 400px;
      object-position: -100px 0;
    }
  }

  .selected_contact {
    background-color: $secondary-light !important;
    border-bottom: 1px solid $secondary;
    border-top: 1px solid $secondary;
  }
  td.exits-column.active,
  td.new-column.active,
  td.all-column.active {
    background-color: $secondary-light;

    .widget-value {
      font-weight: bold;
    }
  }

  tr.disabled {
    color: $text-muted;
    background-color: $gray-200;
  }

  td.group {
    background-color: $gray-200;
    border-bottom: 2px solid $gray-300;
    border-top: 1px solid $gray-300;
  }

  tr.group {
    background-color: $gray-300;
    border-bottom: 2px solid $gray-400;
    border-top: 1px solid $gray-400;
    color: $body-color;
    font-weight: $font-weight-bold;
  }

  .active i {
    color: $ticker;
  }

  .inactive i {
    color: $danger;
  }

  .sorting_1{
    background-color: $secondary-lighter;
  }

  td.match-score-with-target-icon {
    background-color: $secondary-lighter;
  }

  tr.warning {
    background-color: lighten($danger, 30);
  }
  thead th a {
    color: white;
    &:hover, &:active{
      color: white;
      opacity: .8;
    }
  }
}
.portfolio-name-tooltip-bold {
  font-weight: bold;
  padding: 2px 5px;
}
.portfolio-name-tooltip-blue {
  color: $primary;
  padding: 2px 5px;
}
.portfolio-name-tooltip-orange {
  color: $activist-color;
  padding: 2px 5px;
}
.portfolio-name-tooltip-red {
  color: $invasive-color;
  padding: 2px 5px;
}
.portfolio-name-tooltip-grey {
  color: grey;
  padding: 2px 5px;
}

.highlighted-row {
  background-color: $secondary-light !important;
  border-bottom: 1px solid $secondary;
  border-top: 1px solid $secondary;
}

.contact-other {
  color: #aaaaaa;
}
.contact-preferred {
  font-weight: bold;
}
