.list-tag {
  @extend .badge;
  display: inline-flex;
  align-items: center;
  margin-left: s(1);
  color: $gray-900;
  background-color: $gray-100;
  border-radius: $border-radius-pill;
}

.tag-delete {
  margin-left: s(2);
  font-size: 1rem;

  &:hover {
    color: $secondary;
  }
}
