.nav-sidebar {
  width: 300px;
  padding: 0;
  color: $white;
  list-style-type: none;
  background-color: $primary;

  a {
    position: relative;
    display: flex;
    padding-right: 2rem;
    padding-left: 1rem;
    color: rgba($white, .5);
    text-decoration: none;

    .active > & {
      color: rgba($white, 1);
    }
  }

  i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    order: 2;
    width: 2rem;
  }
}

.tipsy {
  display: none;
}
