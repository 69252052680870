div.algo-graph {
  border: none;
  border-radius: 5px;
  height: 250px;
  width: 100%;
}

div.algo-wrapper {
  margin-bottom: 20px;
  &.border-success {
    border-width: 5px;
  }

  .btn-toolbar {
    button {
      min-width: 70px;
    }
  }

  .card-header:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      display: inline-block;
    }
  }
}