.main-navbar {
  position: fixed;
  top: 0;
  z-index: $zindex-sticky;
  width: 100%;
  min-height: $main-navbar-height;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: $box-shadow-sm;
}

.main-navbar-container {
  @include make-container();
  display: grid;
  grid-template-columns: 1fr auto;
  gap: s(3);
  align-items: center;
  width: 100%;

  @include media-breakpoint-up("lg") {
    .nav-link i {
      padding-left: .1rem;
      font-size: 1.25rem;
    }
  }
}

.main-navbar-brand {
  display: flex;
  align-items: center;
}

.main-navbar-logo {
  @include media-breakpoint-down("lg") {
    width: 9rem;
  }
}

.main-navbar-title {
  padding-right: .5rem;
  margin-right: .5rem;
  color: $primary;
  border-right: 1px solid $primary;

  @include media-breakpoint-down("lg") {
    display: none;
  }
  @include media-breakpoint-down("lg") {
    font-size: $font-size-xs;
  }
  @include media-breakpoint-up("lg") {
    font-size: $font-size-base;
    font-weight: $font-weight-light;
  }
}

.main-navbar-form {
  padding: 0 s(3) s(2);
}

.main-navbar-nav {
  @include media-breakpoint-up("lg") {
    > * {
      display: flex;
      align-items: center;
      padding-left: .5rem;
    }
  }
}


