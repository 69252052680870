.global-search {
  width: 25rem !important;
}

.do-global-search {
  opacity: 0;
  transition: .3s;
}

.ui-menu-item-wrapper:hover .do-global-search {
  opacity: 1;
}