//stylelint-disable selector-no-qualifying-type
//stylelint-disable selector-max-type
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}

ul.ui-autocomplete {
  padding: 0;
  margin: 0;
}

ul.ui-autocomplete > li {
  padding: 10px 15px;
  margin-bottom: -1px;
  border: 1px solid #ddd;
}

ul.ui-autocomplete > li:hover {
  background-color: #f5f5f5;
}

ul.ui-autocomplete > li > a:hover {
  text-decoration: none;
}

/* categorized autocompletion */
.ui-autocomplete-category {
  font-weight: bold;
  color: #3d3;
}

.ui-autocomplete-loading {
  background: url("/images/loader.svg") no-repeat scroll 95% center white !important;
}

.ui-autocomplete {
  overflow-y: scroll;
}
