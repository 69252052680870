/* stylelint-disable declaration-no-important */
.adv-search-group {
  max-height: 15rem;

  .panel{
    height: 100%;
    background-color: $white !important;
  }

  .panel-body{
    height: 13rem;
    overflow: auto;
  }

  .filter-container {
    font-size: $font-size-sm;
  }
}

