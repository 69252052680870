.page-header{
  display: flex;
  flex-wrap: wrap;
  gap: s(1) s(3);
  align-items: center;
  justify-content: space-between;
  padding-top: s(3);
  margin-bottom: s(3);

  > * {
    flex-shrink: 0;
    margin-bottom: 0;
  }
}
