.search-collapse {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-collapse-icon {
  position: absolute;
  left: 0;
  font-size: $font-size-lg;
  color: $primary;
}

.search-collapse-input {
  padding: .5rem .5rem .5rem 2em;
  margin: 0;
  font-size: $font-size-sm;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  width: 14em;
  border-bottom: 1px solid $secondary;
}
