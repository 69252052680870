/*! jQuery UI - v1.12.1 - 2019-12-16
* http://jqueryui.com
* Includes: draggable.css, core.css, resizable.css, selectable.css, sortable.css, autocomplete.css, menu.css, button.css, controlgroup.css, checkboxradio.css, dialog.css, slider.css, spinner.css, tabs.css, tooltip.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=base&cornerRadiusShadow=8px&offsetLeftShadow=0px&offsetTopShadow=0px&thicknessShadow=5px&opacityShadow=30&bgImgOpacityShadow=0&bgTextureShadow=flat&bgColorShadow=666666&opacityOverlay=30&bgImgOpacityOverlay=0&bgTextureOverlay=flat&bgColorOverlay=aaaaaa&iconColorError=cc0000&fcError=5f3f3f&borderColorError=f1a899&bgTextureError=flat&bgColorError=fddfdf&iconColorHighlight=777620&fcHighlight=777620&borderColorHighlight=dad55e&bgTextureHighlight=flat&bgColorHighlight=fffa90&iconColorActive=ffffff&fcActive=ffffff&borderColorActive=003eff&bgTextureActive=flat&bgColorActive=007fff&iconColorHover=555555&fcHover=2b2b2b&borderColorHover=cccccc&bgTextureHover=flat&bgColorHover=ededed&iconColorDefault=777777&fcDefault=454545&borderColorDefault=c5c5c5&bgTextureDefault=flat&bgColorDefault=f6f6f6&iconColorContent=444444&fcContent=333333&borderColorContent=dddddd&bgTextureContent=flat&bgColorContent=ffffff&iconColorHeader=444444&fcHeader=333333&borderColorHeader=dddddd&bgTextureHeader=flat&bgColorHeader=e9e9e9&cornerRadius=3px&fwDefault=normal&fsDefault=1em&ffDefault=Arial%2CHelvetica%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */

.ui-draggable-handle {
  touch-action: none;
  touch-action: none;
}

/* Layout helpers
---------------------------------- */
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}
.ui-helper-reset {
  padding: 0;
  margin: 0;
  font-size: 100%;
  line-height: 1.3;
  text-decoration: none;
  list-style: none;
  border: 0;
  outline: 0;
}
.ui-helper-clearfix::before,
.ui-helper-clearfix::after {
  display: table;
  border-collapse: collapse;
  content: "";
}
.ui-helper-clearfix::after {
  clear: both;
}
.ui-helper-zfix {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=0); /* support: IE8 */
  opacity: 0;
}

.ui-front {
  z-index: 100;
}


/* Interaction Cues
---------------------------------- */
.ui-state-disabled {
  pointer-events: none;
  cursor: default !important;
}


/* Icons
---------------------------------- */
.ui-icon {
  position: relative;
  display: inline-block;
  margin-top: -.25em;
  overflow: hidden;
  text-indent: -99999px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  display: block;
  margin-left: -8px;
}

/* Misc visuals
---------------------------------- */

/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-resizable {
  position: relative;
}
.ui-resizable-handle {
  position: absolute;
  display: block;
  font-size: .1px;
  touch-action: none;
  touch-action: none;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}
.ui-resizable-n {
  top: -5px;
  left: 0;
  width: 100%;
  height: 7px;
  cursor: n-resize;
}
.ui-resizable-s {
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 7px;
  cursor: s-resize;
}
.ui-resizable-e {
  top: 0;
  right: -5px;
  width: 7px;
  height: 100%;
  cursor: e-resize;
}
.ui-resizable-w {
  top: 0;
  left: -5px;
  width: 7px;
  height: 100%;
  cursor: w-resize;
}
.ui-resizable-se {
  right: 1px;
  bottom: 1px;
  width: 12px;
  height: 12px;
  cursor: se-resize;
}
.ui-resizable-sw {
  bottom: -5px;
  left: -5px;
  width: 9px;
  height: 9px;
  cursor: sw-resize;
}
.ui-resizable-nw {
  top: -5px;
  left: -5px;
  width: 9px;
  height: 9px;
  cursor: nw-resize;
}
.ui-resizable-ne {
  top: -5px;
  right: -5px;
  width: 9px;
  height: 9px;
  cursor: ne-resize;
}
.ui-selectable {
  touch-action: none;
  touch-action: none;
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted black;
}
.ui-sortable-handle {
  touch-action: none;
  touch-action: none;
}
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}

.ui-menu {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: 0;
}
.ui-menu .ui-menu {
  position: absolute;
}
.ui-menu .ui-menu-item {
  margin: 0;

  /* support: IE10, see #8844 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  cursor: pointer;
}
.ui-menu .ui-menu-item-wrapper {
  position: relative;
  padding: 3px 1em 3px .4em;
}
.ui-menu .ui-menu-divider {
  height: 0;
  margin: 5px 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px;
}

/* icon support */
.ui-menu-icons {
  position: relative;
}
.ui-menu-icons .ui-menu-item-wrapper {
  padding-left: 2em;
}

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
  right: 0;
  left: auto;
}
.ui-button {
  position: relative;
  display: inline-block;
  padding: .4em 1em;
  margin-right: .1em;

  /* Support: IE <= 11 */
  overflow: visible;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.ui-button,
.ui-button:link,
.ui-button:visited,
.ui-button:hover,
.ui-button:active {
  text-decoration: none;
}

/* to make room for the icon, a width needs to be set here */
.ui-button-icon-only {
  box-sizing: border-box;
  width: 2em;
  text-indent: -9999px;
  white-space: nowrap;
}

/* no icon support for input elements */
input.ui-button.ui-button-icon-only {
  text-indent: 0;
}

/* button icon element(s) */
.ui-button-icon-only .ui-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}

.ui-button.ui-icon-notext .ui-icon {
  width: 2.1em;
  height: 2.1em;
  padding: 0;
  text-indent: -9999px;
  white-space: nowrap;

}

input.ui-button.ui-icon-notext .ui-icon {
  width: auto;
  height: auto;
  padding: .4em 1em;
  text-indent: 0;
  white-space: normal;
}

/* workarounds */

/* Support: Firefox 5 - 40 */
input.ui-button::-moz-focus-inner,
button.ui-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.ui-controlgroup {
  display: inline-block;
  vertical-align: middle;
}
.ui-controlgroup > .ui-controlgroup-item {
  float: left;
  margin-right: 0;
  margin-left: 0;
}
.ui-controlgroup > .ui-controlgroup-item:focus,
.ui-controlgroup > .ui-controlgroup-item.ui-visual-focus {
  z-index: 9999;
}
.ui-controlgroup-vertical > .ui-controlgroup-item {
  display: block;
  float: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}
.ui-controlgroup-vertical .ui-controlgroup-item {
  box-sizing: border-box;
}
.ui-controlgroup .ui-controlgroup-label {
  padding: .4em 1em;
}
.ui-controlgroup .ui-controlgroup-label span {
  font-size: 80%;
}
.ui-controlgroup-horizontal .ui-controlgroup-label + .ui-controlgroup-item {
  border-left: none;
}
.ui-controlgroup-vertical .ui-controlgroup-label + .ui-controlgroup-item {
  border-top: none;
}
.ui-controlgroup-horizontal .ui-controlgroup-label.ui-widget-content {
  border-right: none;
}
.ui-controlgroup-vertical .ui-controlgroup-label.ui-widget-content {
  border-bottom: none;
}

/* Spinner specific style fixes */
.ui-controlgroup-vertical .ui-spinner-input {
  /* Support: IE8 only, Android < 4.4 only */
  width: 75%;
  width: calc(100% - 2.4em);
}
.ui-controlgroup-vertical .ui-spinner .ui-spinner-up {
  border-top-style: solid;
}

.ui-checkboxradio-label .ui-icon-background {
  border: none;
  border-radius: .12em;
  box-shadow: inset 1px 1px 1px #ccc;
}
.ui-checkboxradio-radio-label .ui-icon-background {
  width: 16px;
  height: 16px;
  overflow: visible;
  border: none;
  border-radius: 1em;
}
.ui-checkboxradio-radio-label.ui-checkboxradio-checked .ui-icon,
.ui-checkboxradio-radio-label.ui-checkboxradio-checked:hover .ui-icon {
  width: 8px;
  height: 8px;
  background-image: none;
  border-style: solid;
  border-width: 4px;
}
.ui-checkboxradio-disabled {
  pointer-events: none;
}
.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  padding: .2em;
  outline: 0;
}
.ui-dialog .ui-dialog-titlebar {
  position: relative;
  padding: .4em 1em;
}
.ui-dialog .ui-dialog-title {
  float: left;
  width: 90%;
  margin: .1em 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  top: 50%;
  right: .3em;
  width: 20px;
  height: 20px;
  padding: 1px;
  margin: -10px 0 0;
}
.ui-dialog .ui-dialog-content {
  position: relative;
  padding: .5em 1em;
  overflow: auto;
  background: none;
  border: 0;
}
.ui-dialog .ui-dialog-buttonpane {
  padding: .3em 1em .5em .4em;
  margin-top: .5em;
  text-align: left;
  background-image: none;
  border-width: 1px 0 0;
}
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}
.ui-dialog .ui-dialog-buttonpane button {
  margin: .5em .4em .5em 0;
  cursor: pointer;
}
.ui-dialog .ui-resizable-n {
  top: 0;
  height: 2px;
}
.ui-dialog .ui-resizable-e {
  right: 0;
  width: 2px;
}
.ui-dialog .ui-resizable-s {
  bottom: 0;
  height: 2px;
}
.ui-dialog .ui-resizable-w {
  left: 0;
  width: 2px;
}
.ui-dialog .ui-resizable-se,
.ui-dialog .ui-resizable-sw,
.ui-dialog .ui-resizable-ne,
.ui-dialog .ui-resizable-nw {
  width: 7px;
  height: 7px;
}
.ui-dialog .ui-resizable-se {
  right: 0;
  bottom: 0;
}
.ui-dialog .ui-resizable-sw {
  bottom: 0;
  left: 0;
}
.ui-dialog .ui-resizable-ne {
  top: 0;
  right: 0;
}
.ui-dialog .ui-resizable-nw {
  top: 0;
  left: 0;
}
.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  touch-action: none;
  touch-action: none;
  cursor: default;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  font-size: .7em;
  background-position: 0 0;
  border: 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: .8em;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-bottom: -.6em;
  margin-left: 0;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
.ui-spinner {
  position: relative;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  vertical-align: middle;
}
.ui-spinner-input {
  padding: .222em 0;
  margin: .2em 0;
  margin-right: 2em;
  margin-left: .4em;
  color: inherit;
  vertical-align: middle;
  background: none;
  border: none;
}
.ui-spinner-button {
  position: absolute;
  right: 0;
  display: block;
  width: 1.6em;
  height: 50%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: .5em;
  text-align: center;
  cursor: default;
}

/* more specificity required here to override default borders */
.ui-spinner a.ui-spinner-button {
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
}
.ui-spinner-up {
  top: 0;
}
.ui-spinner-down {
  bottom: 0;
}
.ui-tabs {
  position: relative;/* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
  padding: .2em;
}
.ui-tabs .ui-tabs-nav {
  padding: .2em .2em 0;
  margin: 0;
}
.ui-tabs .ui-tabs-nav li {
  position: relative;
  top: 0;
  float: left;
  padding: 0;
  margin: 1px .2em 0 0;
  white-space: nowrap;
  list-style: none;
  border-bottom-width: 0;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  float: left;
  padding: .5em 1em;
  text-decoration: none;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  padding-bottom: 1px;
  margin-bottom: -1px;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-state-disabled .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-tabs-loading .ui-tabs-anchor {
  cursor: text;
}
.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
  cursor: pointer;
}
.ui-tabs .ui-tabs-panel {
  display: block;
  padding: 1em 1.4em;
  background: none;
  border-width: 0;
}
.ui-tooltip {
  position: absolute;
  z-index: 9999;
  max-width: 300px;
  padding: 8px;
}
body .ui-tooltip {
  border-width: 2px;
}

/* Component containers
---------------------------------- */
.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5;
}
.ui-widget-content {
  color: #333;
  background: #fff;
  border: 1px solid #ddd;
}
.ui-widget-content a {
  color: #333;
}
.ui-widget-header {
  font-weight: bold;
  color: #333;
  background: #e9e9e9;
  border: 1px solid #ddd;
}
.ui-widget-header a {
  color: #333;
}

/* Interaction states
---------------------------------- */
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
/* We use html here because we need a greater specificity to make sure disabled
works properly when clicked or hovered */
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  font-weight: normal;
  color: #454545;
  background: #f6f6f6;
  border: 1px solid #c5c5c5;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: #454545;
  text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  font-weight: normal;
  color: #2b2b2b;
  background: #ededed;
  border: 1px solid #ccc;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: 0 0 3px 1px rgb(94, 158, 214);
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  font-weight: normal;
  color: #fff;
  background: #007fff;
  border: 1px solid #003eff;
}
.ui-icon-background,
.ui-state-active .ui-icon-background {
  background-color: #fff;
  border: #003eff;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #fff;
  text-decoration: none;
}

/* Interaction Cues
---------------------------------- */
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  color: #777620;
  background: #fffa90;
  border: 1px solid #dad55e;
}
.ui-state-checked {
  background: #fffa90;
  border: 1px solid #dad55e;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #777620;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  color: #5f3f3f;
  background: #fddfdf;
  border: 1px solid #f1a899;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #5f3f3f;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  font-weight: normal;
  filter: alpha(opacity=70); /* support: IE8 */
  opacity: .7;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  background-image: none;
  filter: alpha(opacity=35); /* support: IE8 */
  opacity: .35;
}
.ui-state-disabled .ui-icon {
  filter: alpha(opacity=35); /* support: IE8 - See #6059 */
}

/* Icons
---------------------------------- */

/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}
.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png");
}
.ui-widget-header .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  background-image: url("images/ui-icons_555555_256x240.png");
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
  background-image: url("images/ui-icons_ffffff_256x240.png");
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
  background-image: url("images/ui-icons_777620_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("images/ui-icons_cc0000_256x240.png");
}
.ui-button .ui-icon {
  background-image: url("images/ui-icons_777777_256x240.png");
}

/* positioning */
.ui-icon-blank { background-position: 16px 16px; }
.ui-icon-caret-1-n { background-position: 0 0; }
.ui-icon-caret-1-ne { background-position: -16px 0; }
.ui-icon-caret-1-e { background-position: -32px 0; }
.ui-icon-caret-1-se { background-position: -48px 0; }
.ui-icon-caret-1-s { background-position: -65px 0; }
.ui-icon-caret-1-sw { background-position: -80px 0; }
.ui-icon-caret-1-w { background-position: -96px 0; }
.ui-icon-caret-1-nw { background-position: -112px 0; }
.ui-icon-caret-2-n-s { background-position: -128px 0; }
.ui-icon-caret-2-e-w { background-position: -144px 0; }
.ui-icon-triangle-1-n { background-position: 0 -16px; }
.ui-icon-triangle-1-ne { background-position: -16px -16px; }
.ui-icon-triangle-1-e { background-position: -32px -16px; }
.ui-icon-triangle-1-se { background-position: -48px -16px; }
.ui-icon-triangle-1-s { background-position: -65px -16px; }
.ui-icon-triangle-1-sw { background-position: -80px -16px; }
.ui-icon-triangle-1-w { background-position: -96px -16px; }
.ui-icon-triangle-1-nw { background-position: -112px -16px; }
.ui-icon-triangle-2-n-s { background-position: -128px -16px; }
.ui-icon-triangle-2-e-w { background-position: -144px -16px; }
.ui-icon-arrow-1-n { background-position: 0 -32px; }
.ui-icon-arrow-1-ne { background-position: -16px -32px; }
.ui-icon-arrow-1-e { background-position: -32px -32px; }
.ui-icon-arrow-1-se { background-position: -48px -32px; }
.ui-icon-arrow-1-s { background-position: -65px -32px; }
.ui-icon-arrow-1-sw { background-position: -80px -32px; }
.ui-icon-arrow-1-w { background-position: -96px -32px; }
.ui-icon-arrow-1-nw { background-position: -112px -32px; }
.ui-icon-arrow-2-n-s { background-position: -128px -32px; }
.ui-icon-arrow-2-ne-sw { background-position: -144px -32px; }
.ui-icon-arrow-2-e-w { background-position: -160px -32px; }
.ui-icon-arrow-2-se-nw { background-position: -176px -32px; }
.ui-icon-arrowstop-1-n { background-position: -192px -32px; }
.ui-icon-arrowstop-1-e { background-position: -208px -32px; }
.ui-icon-arrowstop-1-s { background-position: -224px -32px; }
.ui-icon-arrowstop-1-w { background-position: -240px -32px; }
.ui-icon-arrowthick-1-n { background-position: 1px -48px; }
.ui-icon-arrowthick-1-ne { background-position: -16px -48px; }
.ui-icon-arrowthick-1-e { background-position: -32px -48px; }
.ui-icon-arrowthick-1-se { background-position: -48px -48px; }
.ui-icon-arrowthick-1-s { background-position: -64px -48px; }
.ui-icon-arrowthick-1-sw { background-position: -80px -48px; }
.ui-icon-arrowthick-1-w { background-position: -96px -48px; }
.ui-icon-arrowthick-1-nw { background-position: -112px -48px; }
.ui-icon-arrowthick-2-n-s { background-position: -128px -48px; }
.ui-icon-arrowthick-2-ne-sw { background-position: -144px -48px; }
.ui-icon-arrowthick-2-e-w { background-position: -160px -48px; }
.ui-icon-arrowthick-2-se-nw { background-position: -176px -48px; }
.ui-icon-arrowthickstop-1-n { background-position: -192px -48px; }
.ui-icon-arrowthickstop-1-e { background-position: -208px -48px; }
.ui-icon-arrowthickstop-1-s { background-position: -224px -48px; }
.ui-icon-arrowthickstop-1-w { background-position: -240px -48px; }
.ui-icon-arrowreturnthick-1-w { background-position: 0 -64px; }
.ui-icon-arrowreturnthick-1-n { background-position: -16px -64px; }
.ui-icon-arrowreturnthick-1-e { background-position: -32px -64px; }
.ui-icon-arrowreturnthick-1-s { background-position: -48px -64px; }
.ui-icon-arrowreturn-1-w { background-position: -64px -64px; }
.ui-icon-arrowreturn-1-n { background-position: -80px -64px; }
.ui-icon-arrowreturn-1-e { background-position: -96px -64px; }
.ui-icon-arrowreturn-1-s { background-position: -112px -64px; }
.ui-icon-arrowrefresh-1-w { background-position: -128px -64px; }
.ui-icon-arrowrefresh-1-n { background-position: -144px -64px; }
.ui-icon-arrowrefresh-1-e { background-position: -160px -64px; }
.ui-icon-arrowrefresh-1-s { background-position: -176px -64px; }
.ui-icon-arrow-4 { background-position: 0 -80px; }
.ui-icon-arrow-4-diag { background-position: -16px -80px; }
.ui-icon-extlink { background-position: -32px -80px; }
.ui-icon-newwin { background-position: -48px -80px; }
.ui-icon-refresh { background-position: -64px -80px; }
.ui-icon-shuffle { background-position: -80px -80px; }
.ui-icon-transfer-e-w { background-position: -96px -80px; }
.ui-icon-transferthick-e-w { background-position: -112px -80px; }
.ui-icon-folder-collapsed { background-position: 0 -96px; }
.ui-icon-folder-open { background-position: -16px -96px; }
.ui-icon-document { background-position: -32px -96px; }
.ui-icon-document-b { background-position: -48px -96px; }
.ui-icon-note { background-position: -64px -96px; }
.ui-icon-mail-closed { background-position: -80px -96px; }
.ui-icon-mail-open { background-position: -96px -96px; }
.ui-icon-suitcase { background-position: -112px -96px; }
.ui-icon-comment { background-position: -128px -96px; }
.ui-icon-person { background-position: -144px -96px; }
.ui-icon-print { background-position: -160px -96px; }
.ui-icon-trash { background-position: -176px -96px; }
.ui-icon-locked { background-position: -192px -96px; }
.ui-icon-unlocked { background-position: -208px -96px; }
.ui-icon-bookmark { background-position: -224px -96px; }
.ui-icon-tag { background-position: -240px -96px; }
.ui-icon-home { background-position: 0 -112px; }
.ui-icon-flag { background-position: -16px -112px; }
.ui-icon-calendar { background-position: -32px -112px; }
.ui-icon-cart { background-position: -48px -112px; }
.ui-icon-pencil { background-position: -64px -112px; }
.ui-icon-clock { background-position: -80px -112px; }
.ui-icon-disk { background-position: -96px -112px; }
.ui-icon-calculator { background-position: -112px -112px; }
.ui-icon-zoomin { background-position: -128px -112px; }
.ui-icon-zoomout { background-position: -144px -112px; }
.ui-icon-search { background-position: -160px -112px; }
.ui-icon-wrench { background-position: -176px -112px; }
.ui-icon-gear { background-position: -192px -112px; }
.ui-icon-heart { background-position: -208px -112px; }
.ui-icon-star { background-position: -224px -112px; }
.ui-icon-link { background-position: -240px -112px; }
.ui-icon-cancel { background-position: 0 -128px; }
.ui-icon-plus { background-position: -16px -128px; }
.ui-icon-plusthick { background-position: -32px -128px; }
.ui-icon-minus { background-position: -48px -128px; }
.ui-icon-minusthick { background-position: -64px -128px; }
.ui-icon-close { background-position: -80px -128px; }
.ui-icon-closethick { background-position: -96px -128px; }
.ui-icon-key { background-position: -112px -128px; }
.ui-icon-lightbulb { background-position: -128px -128px; }
.ui-icon-scissors { background-position: -144px -128px; }
.ui-icon-clipboard { background-position: -160px -128px; }
.ui-icon-copy { background-position: -176px -128px; }
.ui-icon-contact { background-position: -192px -128px; }
.ui-icon-image { background-position: -208px -128px; }
.ui-icon-video { background-position: -224px -128px; }
.ui-icon-script { background-position: -240px -128px; }
.ui-icon-alert { background-position: 0 -144px; }
.ui-icon-info { background-position: -16px -144px; }
.ui-icon-notice { background-position: -32px -144px; }
.ui-icon-help { background-position: -48px -144px; }
.ui-icon-check { background-position: -64px -144px; }
.ui-icon-bullet { background-position: -80px -144px; }
.ui-icon-radio-on { background-position: -96px -144px; }
.ui-icon-radio-off { background-position: -112px -144px; }
.ui-icon-pin-w { background-position: -128px -144px; }
.ui-icon-pin-s { background-position: -144px -144px; }
.ui-icon-play { background-position: 0 -160px; }
.ui-icon-pause { background-position: -16px -160px; }
.ui-icon-seek-next { background-position: -32px -160px; }
.ui-icon-seek-prev { background-position: -48px -160px; }
.ui-icon-seek-end { background-position: -64px -160px; }
.ui-icon-seek-start { background-position: -80px -160px; }

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first { background-position: -80px -160px; }
.ui-icon-stop { background-position: -96px -160px; }
.ui-icon-eject { background-position: -112px -160px; }
.ui-icon-volume-off { background-position: -128px -160px; }
.ui-icon-volume-on { background-position: -144px -160px; }
.ui-icon-power { background-position: 0 -176px; }
.ui-icon-signal-diag { background-position: -16px -176px; }
.ui-icon-signal { background-position: -32px -176px; }
.ui-icon-battery-0 { background-position: -48px -176px; }
.ui-icon-battery-1 { background-position: -64px -176px; }
.ui-icon-battery-2 { background-position: -80px -176px; }
.ui-icon-battery-3 { background-position: -96px -176px; }
.ui-icon-circle-plus { background-position: 0 -192px; }
.ui-icon-circle-minus { background-position: -16px -192px; }
.ui-icon-circle-close { background-position: -32px -192px; }
.ui-icon-circle-triangle-e { background-position: -48px -192px; }
.ui-icon-circle-triangle-s { background-position: -64px -192px; }
.ui-icon-circle-triangle-w { background-position: -80px -192px; }
.ui-icon-circle-triangle-n { background-position: -96px -192px; }
.ui-icon-circle-arrow-e { background-position: -112px -192px; }
.ui-icon-circle-arrow-s { background-position: -128px -192px; }
.ui-icon-circle-arrow-w { background-position: -144px -192px; }
.ui-icon-circle-arrow-n { background-position: -160px -192px; }
.ui-icon-circle-zoomin { background-position: -176px -192px; }
.ui-icon-circle-zoomout { background-position: -192px -192px; }
.ui-icon-circle-check { background-position: -208px -192px; }
.ui-icon-circlesmall-plus { background-position: 0 -208px; }
.ui-icon-circlesmall-minus { background-position: -16px -208px; }
.ui-icon-circlesmall-close { background-position: -32px -208px; }
.ui-icon-squaresmall-plus { background-position: -48px -208px; }
.ui-icon-squaresmall-minus { background-position: -64px -208px; }
.ui-icon-squaresmall-close { background-position: -80px -208px; }
.ui-icon-grip-dotted-vertical { background-position: 0 -224px; }
.ui-icon-grip-dotted-horizontal { background-position: -16px -224px; }
.ui-icon-grip-solid-vertical { background-position: -32px -224px; }
.ui-icon-grip-solid-horizontal { background-position: -48px -224px; }
.ui-icon-gripsmall-diagonal-se { background-position: -64px -224px; }
.ui-icon-grip-diagonal-se { background-position: -80px -224px; }


/* Misc visuals
---------------------------------- */

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 3px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 3px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 3px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 3px;
}

/* Overlays */
.ui-widget-overlay {
  background: #aaa;
  filter: alpha(opacity=30); /* support: IE8 */
  opacity: .3;
}
.ui-widget-shadow {
  box-shadow: 0 0 5px #666;
  box-shadow: 0 0 5px #666;
}
