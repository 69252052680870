.vm-page-item {
  height: 1.5rem;
  padding: s(3) s(1);
  font-size: $font-size-sm;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    color: $white;
    background: $secondary;
  }

  &.selected {
    font-weight: bold;
  }
}

.vm-page-presence {
  min-width: 2.5rem;
  padding-right: s(1);

  &.ui-state-active {
    font-weight: $font-weight-bold;
    color: inherit;
    background: none;
    border: none;
  }
}

.img-selector {
  position: relative;

  ul {
    max-height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
  }

  li a {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: $font-weight-bold;
  }

  li img {
    width: 100px;
    max-height: 100px;
    object-fit: cover;
    margin-left: auto;
  }
}

#vm-qur {
  .vm-page-download {
    display: none;
  }
}
