.sticky-buttons-top {
  --bs-gutter-x: 1.5rem;
  position: sticky;
  top: $main-navbar-height;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  background-color: $body-bg;
  border-bottom: 1px solid $border-color;
}

.sticky-buttons-bottom {
  --bs-gutter-x: 1.5rem;
  position: sticky;
  bottom: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  background-color: $body-bg;
  border-top: 1px solid $border-color;
}
