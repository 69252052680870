.main-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: $zindex-fixed;
  grid-area: sidebar;
  width: 100%;
  overflow-y: auto;
  background-color: $main-sidebar-background;
  box-shadow: none;
  transition: width .3s ease-out, left .6s ease-out, box-shadow .4s ease-out;
  will-change: width, left, box-shadow;

  @include media-breakpoint-up("lg"){
    top: $main-navbar-height;
    left: 0;
    width: $main-sidebar-width-collapsed;
    box-shadow: none;
    transition: width .3s ease-out, box-shadow .3s ease-out;
    overflow-y: unset;
  }

  .is-fixed > & {
    @include media-breakpoint-down("lg"){
      left: 0;
      box-shadow: 0 10px 20px rgba($black, .8);
      transition: width .01s, left .6s ease-out, box-shadow .4s ease-out;
    }
  }
}

.main-sidebar-sm {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: s(1);
  padding-bottom: s(2);
  @include media-breakpoint-up("lg"){
    display: none;
  }

  .btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $main-sidebar-width-collapsed;
    height: $main-sidebar-width-collapsed;
    margin-left: auto;
    border-radius: 0;
  }
}

.main-sidebar-container {
  overflow-x: hidden;

  @include media-breakpoint-up("lg"){
    overflow-x: unset;
  }
}

.main-sidebar-item,
.main-sidebar-sm .nav-link {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-height: $main-sidebar-item-height;
  font-size: $font-size-base;
  color: $main-sidebar-color;
  text-decoration: none;
  transition: background .5s ease-out;

  &.active {
    color: $main-sidebar-color-active;
    background: $main-sidebar-background-active;
  }


  &:not(.active):hover {
    color: $main-sidebar-color-hover;
    background: $main-sidebar-background-hover;
  }

  span {
    display: inline-block;
    min-width: 0;
    overflow: hidden;
    text-indent: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.main-sidebar-icon,
.main-sidebar-sm [class*="icon"]{
  min-width: $main-sidebar-width-collapsed;
  font-size: 1rem;
  text-align: center;
}

.main-sidebar-item {
  @include media-breakpoint-up("lg"){
    position: relative;

    &:hover span {
      width: $main-sidebar-width - $main-sidebar-width-collapsed;
    }

    span {
      position: absolute;
      width: 0;
      left: $main-sidebar-width-collapsed;
      top: 0;
      height: $main-sidebar-item-height;
      display: flex;
      align-items: center;
      transition: width .2s ease-out;
      background: $main-sidebar-background;
    }
  }
}


