// stylelint-disable selector-class-pattern

.contact_tag {
  @extend .badge;
  @extend .rounded-pill;
  margin-right: s(1);
  margin-left: s(1);
  color: $body-color;
  background-color: $light;

  &.industry_tag {
    background-color: $beryl;

    &.rank1::before,
    &.rank2::before,
    &.rank3::before,
    &.rank4::before,
    &.rank5::before {
      display: inline-block;
      width: s(2);
      height: s(2);
      margin-right: s(1);
      content: "";
      border-radius: 50%;

    }
    &.rank1::before {
      background-color: lighten($circuit, 20);
    }

    &.rank2::before {
      background-color: lighten($circuit, 15);
    }

    &.rank3::before {
      background-color: lighten($circuit, 10);
    }

    &.rank4::before {
      background-color: lighten($circuit, 5);

    }

    &.rank5::before {
      background-color: $circuit;
    }
  }

  &.metro_tag {
    color: $white;
    background-color: $celadon;
  }

  &.region_tag {
    color: $white;
    background-color: $purple-citadel;
  }

  &.market_tag {
    background-color: $arctic;
  }

  &.job_function_tag {
    background-color: #eedab1;
  }

  &.not_refinitiv_tag {
    background-color: #0003a4;
    color: #e0e8f1;
  }
}
