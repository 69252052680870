/* stylelint-disable selector-class-pattern */

.slide {

  .ls-title {
    @extend .form-label;
    @extend .fs-sm;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: s(2);
  }

  .sliderInput {
    @extend .form-control;
    @extend .form-control-sm;
    width: auto;
    max-width: 6rem;
  }
}

