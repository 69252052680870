@import "_tipsy";

.tipsy-inner {
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  background-color: $tooltip-bg;
  border: 0;
  border-radius: $tooltip-border-radius;
  min-width: min-content;

  text {
    fill: $tooltip-color !important;
  }

  .highcharts-text-outline {
    stroke: transparent;
  }
}

.tipsy-inner p {
  padding: 0;
}

.tipsy-header {
  display: flex;
  align-items: center;
  padding-bottom: s(1);
  font-size: $font-size-sm !important;
  border-bottom: 1px solid rgba($tooltip-color, .5);

  span {
    font-family: 'Helvetica Neue', 'Asap', Arial, sans-serif;
    font-weight: 500;
    line-height: 1.428571429;
  }
}

.tipsy-close {
  padding-left: 2rem !important;
  margin-left: auto;
  opacity: .5;

  &:hover {
    opacity: 1;
  }
}
