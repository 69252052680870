.tipsy { position: absolute; z-index: 100000; padding: 5px; font-size: 11px; }
.tipsy-inner { padding: 6px 9px 5px; color: #4f3267; text-align: left; background-color: rgba(255, 255, 255, .94); }

/* Rounded corners */
.tipsy-inner { border: 2px solid #666; border-radius: 6px; border-radius: 6px; border-radius: 6px; }

.tipsy-inner .tipsy-header { font-size: 17px; font-weight: 700; }
.tipsy-inner p { padding: 0 8px; margin-top: 10px; font-size: 12px; line-height: 18px; }
.tipsy-inner .tipsy-close { float: right; padding-left: 3px; cursor: pointer; }

/* Uncomment for shadow */

/* .tipsy-inner { box-shadow: 0 0 5px #000000; -webkit-box-shadow: 0 0 5px #000000; -moz-box-shadow: 0 0 5px #000000; } */

.tipsy-arrow { position: absolute; width: 0; height: 0; line-height: 0; border: 5px dashed #000; }

/*
.tipsy-arrow-n {
  background: #e2e4ff;
  border-color: #352145;
  border-bottom-color: #e2e4ff;
  margin-top: 1px;
}
*/

/* Rules to colour arrows */
.tipsy-arrow-n { border-bottom-color: #000; }
.tipsy-arrow-s { border-top-color: #000; }
.tipsy-arrow-e { border-left-color: #000; }
.tipsy-arrow-w { border-right-color: #000; }

.tipsy-n .tipsy-arrow { top: 0; left: 50%; margin-left: -5px; border-top: none; border-right-color: transparent; border-bottom-style: solid; border-left-color: transparent; }
.tipsy-nw .tipsy-arrow { top: 0; left: 10px; border-top: none; border-right-color: transparent; border-bottom-style: solid; border-left-color: transparent; }
.tipsy-ne .tipsy-arrow { top: 0; right: 10px; border-top: none; border-right-color: transparent; border-bottom-style: solid; border-left-color: transparent; }
.tipsy-s .tipsy-arrow { bottom: 0; left: 50%; margin-left: -5px; border-top-style: solid; border-right-color: transparent; border-bottom: none; border-left-color: transparent; }
.tipsy-sw .tipsy-arrow { bottom: 0; left: 10px; border-top-style: solid; border-right-color: transparent; border-bottom: none; border-left-color: transparent; }
.tipsy-se .tipsy-arrow { right: 10px; bottom: 0; border-top-style: solid; border-right-color: transparent; border-bottom: none; border-left-color: transparent; }
.tipsy-e .tipsy-arrow { top: 50%; right: 0; margin-top: -5px; border-top-color: transparent; border-right: none; border-bottom-color: transparent; border-left-style: solid; }
.tipsy-w .tipsy-arrow { top: 50%; left: 0; margin-top: -5px; border-top-color: transparent; border-right-style: solid; border-bottom-color: transparent; border-left: none; }
