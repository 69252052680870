// fix old columns
@for $i from 1 through 12 {
  .col-xs-#{$i} {
    @extend .col-#{$i};
  }
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: $main-navbar-height;
}

.hidden {
  display: none;
}

h1,
h2,
h3 {
  font-weight: $font-weight-lighter;
  color: $primary;
}
