//stylelint-disable property-no-vendor-prefix
.flash {
  $flash-radius: $border-radius;
  $flash-padding-y: s(2);
  $flash-padding-x: .75rem;
  $flash-bg-size: 50px;
  $flash-height: $main-sidebar-item-height;

  --icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 110-2 1 1 0 010 2z'/%3E%3C/svg%3E");
  --bg: #{$ticker};

  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  padding: $flash-padding-y $flash-padding-x;
  font-size: $font-size-sm;
  background-color: $white;
  background-size: 50px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &::before {
    position: relative;
    z-index: 1;
    display: inline-block;
    flex-shrink: 0;
    align-self: center;
    width: $flash-bg-size;
    height: 1.5em;
    margin-right: $flash-padding-x;
    margin-left: - $flash-padding-x;
    content: "";
    background-color: $white;
    -webkit-mask: var(--icon) center/contain no-repeat;
    mask: var(--icon) center/contain no-repeat;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: $flash-bg-size;
    content: "";
    background-color: var(--bg);
    border-top-left-radius: $flash-radius;
    border-bottom-left-radius: $flash-radius;
  }

  &.error {
    --icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-exclamation-triangle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8.982 1.566a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 01-1.1 0L7.1 5.995A.905.905 0 018 5zm.002 6a1 1 0 110 2 1 1 0 010-2z'/%3E%3C/svg%3E");
    --bg: #{$amber};
  }

  & + & {
    margin-top: s(2);
  }
}

.flashes {
  position: fixed;
  top: $main-navbar-height;
  right: $container-padding-x;
  z-index: 1050;
  padding-top: s(2);
}
