@import "bootstrap/scss/modal";

.modal-header {
  color: $white;
  background-color: $secondary;
}

.modal-title {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.modal-footer {
  background-color: $gray-100;
}

.modal-content-loading {
  .modal-body {
    &:not(:empty) {
      animation: fade-in .5s ease-out both;
    }

    &:empty {
      position: relative;
      min-height: 300px;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: initial;
        content: "";
        background-color: $primary;
        mask-image: var(--animated-spinner);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: auto;
      }
    }
  }
}

.modal-xxl {
  max-width: 90%;
}

.modal-xxxl {
  max-width: 95%;
}
