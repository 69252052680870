/* stylelint-disable no-duplicate-at-import-rules */

// colors
$primary: #002f6c;
$primary-light: #8ea3be;
$primary-lighter: #e3e8ef;
$secondary: #00b3dc;
$secondary-dark: darken($secondary, 5%);
$secondary-light: #8eddef;
$secondary-lighter: #f2fbfd;
$totalseller-color: #ff0800;
$buyer-color: #a7cc83;
$seller-color: #ff8480;
$newbuyer-color: #70bf00;
$activist-color: #db8000;
$invasive-color: #ff0800;
$passive-color: #808080;

$white: #fff;
$black: #000;
$gray-100: #f2f3f2;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$sun: #f2cd00;
$ticker: #6cc24a;
$bronze: #a08629;
$platinum: #a2aaad;
$grain: #afa9a0;
$charcoal: #54575a;
$titanium: #00558c;
$arctic: #99d6ea;
$celadon: #85b09a;
$circuit: #006341;
$amber:  #e56a54;
$beryl: #e1e000;
$purple-citadel: #7d55c7;
$burgundy: #672146;
$flag: #9a3324;
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$success:                     $ticker;
$danger:                      $amber;
$body-bg:                     $secondary-lighter;
$body-color:                  $gray-700;

$color-newbuyer:  $ticker;
$color-buyer: lighten($color-newbuyer, 30);
$color-holder: $gray-300;
$color-totalseller: $amber;
$color-seller: lighten($color-totalseller, 30);

$min-contrast-ratio:          2;
$border-color:                $gray-300;

// links
$link-color:                              $primary;
$link-decoration:                         none;
$link-shade-percentage:                   20%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                   null;

// spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
);

// containers
$container-max-widths: (
  xs: 400px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// border radius
$border-radius:               .25rem;
$border-radius-sm:            .2rem;
$border-radius-lg:            .3rem;
$border-radius-pill:          50rem;


// buttons
$btn-close-color:             $white;

$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius-sm;
$btn-border-radius-lg:        $border-radius-lg;

$btn-padding-x:               1rem;
$btn-padding-x-sm:            .75rem;
$btn-padding-x-lg:            1.25rem;


// navbar
$navbar-toggler-border-radius:      .5rem;
$navbar-light-color:                $gray-500;
$navbar-light-hover-color:          rgba($primary, 1);
$navbar-light-active-color:         rgba($secondary, 1);
$navbar-light-disabled-color:       rgba($primary, .4);
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($primary, .1);

// fonts
$font-family-sans-serif:      "HelveticaNeueLTStd", sans-serif;
$font-weight-thin: 100;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-xs:                $font-size-base * .75;
$font-size-sm:                $font-size-base * .915;
$font-size-lg:                $font-size-base * 1.25;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.4;
$line-height-sm:              1.15;
$line-height-lg:              1.8;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  xs: $font-size-xs,
  sm: $font-size-sm,
  md: $font-size-base,
  lg: $font-size-lg
);


//nav and tabs
$nav-link-padding-y:                .5rem;
$nav-link-padding-x:                1rem;
$nav-link-font-size:                $font-size-sm;
$nav-link-color:                    $link-color;
$nav-link-hover-color:              $link-hover-color;
$nav-tabs-link-active-color:        $secondary;

// modals
$modal-header-border-color:         $secondary-dark;
$modal-footer-border-color:         $border-color;
$modal-backdrop-bg:                 $primary;
$modal-backdrop-opacity:            .9;

$modal-sm:                          340px;
$modal-md:                          500px !default;
$modal-lg:                          800px !default;
$modal-xl:                          1140px !default;
$modal-xxl:                         90%;

// tables
$table-variants: (
  "primary":    $primary,
  "secondary":  $secondary,
  "light":      $light,
  "dark":       $dark
);

$table-group-separator-color:       $primary-lighter;

$table-color:                 $body-color;
$table-striped-color:         $table-color;
$table-striped-bg-factor:     .03;
$table-striped-bg:            rgba($primary, $table-striped-bg-factor);
$table-border-width:          0;
$table-hover-color:           $table-color;
$table-hover-bg-factor:       .15;
$table-hover-bg:              rgba($secondary, $table-hover-bg-factor);

// layout-main
$main-sidebar-width-collapsed: 57px;
$main-sidebar-width: 250px;
$main-sidebar-gutter: 10px;
$main-navbar-height: 57px;

$main-sidebar-item-height: 40px;
$main-sidebar-color: rgba($white, .7);
$main-sidebar-color-hover: $white;
$main-sidebar-color-active: $white;
$main-sidebar-background: $secondary;
$main-sidebar-background-active: rgba($white, .2);
$main-sidebar-background-hover: rgba($white, .3);

$offcanvas-bg-color: $white;

$enable-negative-margins: true;

// data tables
$dt-header-bg: $white;
$dt-footer-bg: $white;
$dt-border: 1px solid $primary-light;
$dt-padding: s(2);

// icons
$icons: (
  "airplane": icon-airplane(),
  "arrow-down-circle-fill": icon-arrow-down-circle-fill(),
  "arrow-repeat": icon-arrow-repeat(),
  "arrow-right-circle-fill": icon-arrow-right-circle-fill(),
  "arrow-counterclockwise": icon-arrow-counterclockwise(),
  "arrow-up-circle-fill": icon-arrow-up-circle-fill(),
  "arrow-up-right-square": icon-arrow-up-right-square(),
  "bookmark-check-fill": icon-bookmark-check-fill(),
  "bookmark-plus-fill": icon-bookmark-plus-fill(),
  "bookmarks": icon-bookmarks(),
  "box": icon-box(),
  "box-arrow-in-left": icon-box-arrow-in-left(),
  "boxes": icon-boxes(),
  "building": icon-building(),
  "bullseye": icon-bullseye(),
  "calendar": icon-calendar(),
  "check-circle": icon-check-circle(),
  "check-circle-fill": icon-check-circle-fill(),
  "check2": icon-check2(),
  "check2-square": icon-check2-square(),
  "chevron-down": icon-chevron-down(),
  "chevron-left": icon-chevron-left(),
  "chevron-up": icon-chevron-up(),
  "circle": icon-circle(),
  "circle-fill": icon-circle-fill(),
  "download": icon-download(),
  "dashboard": icon-dashboard(),
  "envelope": icon-envelope(),
  "exclamation-triangle-fill": icon-exclamation-triangle-fill(),
  "eye": icon-eye(),
  "file-earmark-lock": icon-file-earmark-lock(),
  "file-earmark-plus": icon-file-earmark-plus(),
  "file-earmark-text": icon-file-earmark-text(),
  "file-earmark-text-fill": icon-file-earmark-text-fill(),
  "file-graph": icon-file-graph(),
  "file-notes": icon-file-notes(),
  "files": icon-files(),
  "filter": icon-filter(),
  "flag": icon-flag(),
  "gear-fill": icon-gear-fill(),
  "global": icon-global(),
  "history": icon-history(),
  "info-fill": icon-info-fill(),
  "link-45deg": icon-link-45deg(),
  "local": icon-local(),
  "logout": icon-logout(),
  "meetings": icon-meetings(),
  "pencil-fill": icon-pencil-fill(),
  "pencil-square": icon-pencil-square(),
  "people-fill": icon-people-fill(),
  "person": icon-person(),
  "person-x-fill": icon-person-x-fill(),
  "plus-circle-fill": icon-plus-circle-fill(),
  "plus-lg": icon-plus-lg(),
  "plus-square": icon-plus-square(),
  "question": icon-question(),
  "quote": icon-quote(),
  "retail-tools": icon-retail-tools(),
  "save": icon-save(),
  "search": icon-search(),
  "settings": icon-settings(),
  "slash-circle": icon-slash-circle(),
  "sort-down": icon-sort-down(),
  "square": icon-square(),
  "star": icon-star(),
  "star-fill": icon-star-fill(),
  "support": icon-support(),
  "three-dots": icon-three-dots(),
  "three-dots-vertical": icon-three-dots-vertical(),
  "toggle-off": icon-toggle-off(),
  "toggle-on": icon-toggle-on(),
  "trash3": icon-trash3(),
  "trash3-fill": icon-trash3-fill(),
  "unlock-fill": icon-unlock-fill(),
  "x": icon-x(),
  "x-circle-fill": icon-x-circle-fill(),
  "dash-circle-fill": icon-dash-circle-fill(),
  "xls": icon-xls()
);

@import "bootstrap/scss/variables";
