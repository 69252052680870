/* stylelint-disable declaration-no-important */

@import "jquery-ui";

/* Range slider */
.ui-slider {
  border-color: $form-range-track-bg !important;
  border-radius: $form-range-track-border-radius;
}

.ui-slider-horizontal {
  height: $form-range-track-height;
}

.ui-slider-range {
  background-color: $form-range-track-bg;
  border-radius: $form-range-track-border-radius;
  box-shadow: $form-range-track-box-shadow;
}

.ui-slider-handle {
  width: $form-range-thumb-width !important;
  height: $form-range-thumb-height !important;
  background-color: $form-range-thumb-bg !important;
  border: $form-range-thumb-border !important;
  border-radius: $form-range-thumb-border-radius;
  box-shadow: $form-range-thumb-box-shadow;

  &.ui-state-focus {
    background: $form-range-thumb-active-bg !important;
    box-shadow: $form-range-thumb-focus-box-shadow;
  }
}

.ui-autocomplete {
  z-index: 1200;
  border: 0 !important;
  border-radius: $border-radius;
}

.ui-autocomplete > li {
  padding: 0 !important;
  border-color: #c5c5c5;

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  & + li {
    border-top-width: 0;
  }
}

.ui-autocomplete .ui-menu-item-wrapper {
  font-size: $font-size-xs;
  display: block;
  padding: s(2) !important;
}

li.ui-autocomplete-category {
  padding: s(2) !important;
  font-size: $font-size-sm;
  color: inherit;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover{
  background: $secondary !important;
  border: 0 !important;
  font-weight: inherit !important;
}
