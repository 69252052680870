.form-label-required {
  &::after {
    content: " *";
  }
}

// Makes custom validation look like Bootstrap
.has-error {
  input {
    @extend .is-invalid;
  }
  /* stylelint-disable-next-line selector-no-qualifying-type */
  label.help-block, small.help-block, div.help-block{
    @extend .invalid-feedback;
  }
  .dropdown~label.help-block {
    display: block;
  }
}
