// stylelint-disable selector-class-pattern

.meter-wrap{
  position: relative;
}

.meter-wrap,
.meter-value,
.meter-text {
  width: 8.5rem;
  height: 1.2rem;
}

.meter-wrap,
.meter-value {
  background: #bbb; /* url(/path/to/your-image.png) top left no-repeat; */
}

.meter-value.holdings_value {
  background-color: $primary;
}
.meter-value.purchases_value {
  background-color: $ticker;
}
.meter-value.sells_value {
  background-color: $red;
}

.meter-text {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-xs;
  color: $white;
  text-align: center;
}
